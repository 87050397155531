import React from 'react';
import { Text, Center } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Center>
      <Text color={'#aeaeae'}>Made with love by cabrit0</Text>
    </Center>
  );
};

export default Footer;
